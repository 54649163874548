/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import SEO from '../components/Seo'
import device from '../utils/mq'

const Wrapper = styled.div`
  max-width: 1100px;
  padding: 0 15px;
  margin: 0 auto;
  margin-bottom: 40px;
  h1 {
    font-size: 50px;
    letter-spacing: -3px;
    color: #5a55ab;
    text-align: left;
    font-weight: 700;
    @media ${device.tablet} {
      font-size: 70px;
    }
  }
  p {
    max-width: 1000px;
    margin: 0 0 1.56em;
  }
`
const StyledDetails = styled.details`
  display: inline;
  summary {
    font-weight: 700;
    margin: 15px 0;
    font-size: 1.6rem;
    &:hover {
      cursor: pointer;
    }
  }
  ul li {
    font-size: 1.1rem;
  }
`
const StyledListWrapper = styled.div`
  ul li::marker {
    color: #5a55ab;
  }
`

function SkillsList({ pageContext: { data } }) {
  const dataSorted = Object.keys(data)
    .sort()
    .reduce((accumulator, key) => {
      accumulator[key] = data[key]

      return accumulator
    }, {})

  return (
    <Wrapper>
      <SEO
        title="Innential - Skills list"
        description="Innential helps your teams perform and grow through hyper personalised learning."
      />
      <h1>Skills</h1>
      <StyledListWrapper>
        {Object.keys(dataSorted).map((d) => (
          <div key={d}>
            <StyledDetails>
              <summary>{d}</summary>
              <ul>
                {Object.entries(dataSorted[d])
                  .sort((a, b) =>
                    Object.keys(a[1])[0].localeCompare(Object.keys(b[1])[0])
                  )
                  .map(([key, value]) => (
                    <li key={key}>{Object.keys(value)}</li>
                  ))}
              </ul>
            </StyledDetails>
          </div>
        ))}
      </StyledListWrapper>
    </Wrapper>
  )
}

export default SkillsList
